<template>
  <div class="row">
    <PageTitle :title="'Home'" />
  </div>

  <!-- Pesanan Baru -->
  <div class="card mb-5 mb-xl-10">
    <div class="card-body p-9">
      <div class="row mb-4">
        <div style="flex: 0 0 auto; width: 80%">
          <h3 class="fw-bolder">Pesanan Baru</h3>
        </div>

        <div style="flex: 0 0 auto; width: 20%">
          <!-- <button
            type="button"
            style="width: 100%"
            id="btnFilter"
            class="btn btn-light-dark"
          >
            Lihat Semua
          </button> -->
        </div>
      </div>

      <div v-if="showFilterPesanan">
        <div class="row mb-2">
          <div class="col-md-6 mt-6">
            <label for="" style="font-size: 14px">No. PR</label>
            <input
              type="text"
              class="form-control"
              id="inputNoPR"
              v-model="filter.nNoPR"
              placeholder="Masukkan no. PR"
            />
          </div>
          <div class="col-md-6 mt-6">
            <label for="" style="font-size: 14px">No. PO</label>
            <input
              type="text"
              class="form-control"
              id="inputNoPO"
              v-model="filter.nNoPO"
              placeholder="Masukkan no. PO"
            />
          </div>

          <div class="col-md-12 mt-6">
            <label for="" style="font-size: 14px">Status Persetujuan</label>
            <br />
            <el-select
              @click="selectCabang"
              v-model="filter.nStatus"
              :disabled="disableBranch"
              filterable
              placeholder="Pilih Status Persetujuan"
              v-bind:id="'inputCabang'"
              :popper-class="'cabangSelect'"
            >
              <el-option
                v-for="item in isStatusApproval"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>

          <div class="col-md-12 mt-6">
            <label for="" style="font-size: 14px">Tanggal Pengajuan</label>
            <el-date-picker
              v-model="tanggalPengajuan"
              type="daterange"
              size="large"
              start-placeholder="Pilih tanggal pengajuan awal"
              end-placeholder="Pilih tanggal pengajuan akhir"
              @change="changeDateTime($event)"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-md-7">
            <button
              type="button"
              id="btnClear"
              class="btn btn-white"
              @click="clearFilterPesanan"
            >
              Kosongkan Filter
            </button>
          </div>
          <div class="col-md-5">
            <button
              type="button"
              id="btnApply"
              class="btn btn-dark"
              style="margin-right: 9px; width: 50%"
              @click="filterDataPesanan"
            >
              Terapkan
            </button>
            <button
              type="button"
              id="btnClose"
              class="btn btn-secondary"
              style="width: 47%"
              @click="closeFilterPesanan"
            >
              Batal
            </button>
          </div>
        </div>
      </div>

      <hr />
      <Datatable
        v-if="refreshTable"
        ref="Datatable"
        :table-header="tabelHeader"
        :dataurl="dataurl"
        :filter="listFilter"
        :search="search"
        :routeurl="routeurl"
        :deleteurl="deleteurl"
        :methodeurl="methodeurl"
      >
        <template v-slot:cell-pr_code="{ row: data }">
          {{ data.pr_code }}
        </template>
        <template v-slot:cell-po_code="{ row: data }">
          {{ data.po_code }}
        </template>
        <template v-slot:cell-status="{ row: data }">
          {{ data.status }}
        </template>
        <template v-slot:cell-created_date="{ row: data }">
          {{ $mix.format_date_hour_minute(data.created_date) }}
        </template>
        <template v-slot:cell-action="{ row: data }">
          <button
            type="button"
            v-bind:id="'btnDetail' + data.po_code"
            @click="btnDetail(data.po_code, data.pr_code)"
            class="btn btn-sm btn-light-info"
          >
            <span class="fa fa-search"></span>
          </button>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatableVendorId.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import PageTitle from "@/components/PageTitle.vue";
export default {
  components: {
    Datatable,
    PageTitle,
  },
  data() {
    const fieldsProduksi = [
      { key: "po_code", name: "No PO", sortable: true },
      { key: "created_date", name: "Tanggal Masuk PO", sortable: true },
      { key: "action", name: "Aksi", sortable: true, stickyColumn: true },
    ];
    const fieldsKurir = [
      { key: "po_code", name: "No PO", sortable: true },
      { key: "pr_code", name: "No PR", sortable: true },
      { key: "created_date", name: "Tanggal Masuk PO", sortable: true },
      { key: "action", name: "Aksi", sortable: true, stickyColumn: true },
    ];
    return {
      profile: JSON.parse(this.$store.getters.currentUser),
      dataurl: "/dashboard/v1/get-new-order",
      methodeurl: "post",
      routeurl: "vendor-list-po-purchase-order-detail",
      deleteurl: "",
      tabelHeader:
        JSON.parse(this.$store.getters.currentUser).data.vendor_type_name ===
        "KURIR"
          ? fieldsKurir
          : fieldsProduksi,
      search: null,
      loading: false,
      dialogVisibleDelete: false,
      valDelete: null,
      listFilter: [{}],
      refreshTable: true,
      refreshTable2: true,
      branch_id: "",
      showFilterPesanan: false,
      filter: {
        nNoPO: "",
      },
      tanggalPengajuan: "",
      isStatusApproval: [
        {
          label: "SUBMIT",
          value: "STSBT",
        },
        {
          label: "APPROVE",
          value: "STAPP",
        },
        {
          label: "REVISE",
          value: "STRTN",
        },
        {
          label: "REJECT",
          value: "STRJT",
        },
        {
          label: "COMPLETE",
          value: "STCPT",
        },
      ],
    };
  },
  mounted() {
    if (this.$store.getters.isUserAuthenticated) {
      // this.getUserDetail();
      // this.filterUpdate();
    } else {
      this.$router.replace({ name: "sign-in" });
    }
    setCurrentPageBreadcrumbs("Home", []);
  },
  watch: {
    search(val) {
      if (val.length < 1) {
        this.listFilter[0].role_name = null;
        this.$refs.Datatable.fetchData;
      }
    },
  },
  methods: {
    changeDateTime(val) {
      if (val != null) {
        // this.filter.n_pr_date_end = val[1]+' 23:59:59'
        let start_date = new Date(val[0]);
        let end_date = new Date(val[1]);
        start_date.setHours(0);
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        end_date.setHours(23);
        end_date.setMinutes(59);
        end_date.setSeconds(59);
        this.filter.n_pr_date_start = start_date;
        this.filter.n_pr_date_end = end_date;
        this.listFilter[0].start_date = this.filter.n_pr_date_start;
        this.listFilter[0].end_date = this.filter.n_pr_date_end;
      } else {
        this.filter.n_pr_date_start = null;
        this.filter.n_pr_date_end = null;
        this.listFilter[0].start_date = null;
        this.listFilter[0].end_date = null;
      }
    },
    cariData() {
      let val = this.search;
      if (val.length > 3) {
        this.listFilter[0].role_name = this.search;
        this.forceUpdate();
      }
    },
    // filterUpdate() {
    //   this.listFilter[0].vendor_id = this.profile.data.vendor_id;
    //   this.listFilter[0].vendor_type = this.profile.data.vendor_type_id;
    //   this.forceUpdate();
    // },
    closeFilterPesanan() {
      this.showFilterPesanan = false;
    },
    btnDetail(val, val2) {
      this.$router.push({
        name: this.routeurl,
        params: { id: val },
      });

      if (this.profile.data.vendor_type_name === "KURIR") {
        localStorage.setItem("pr_code_kurir", val2);
      }
    },
    clearFilterPesanan() {
      this.filter.nNoPO = "";
      this.filter.nNoPR = "";
      this.filter.nStatus = "";
      this.filter.n_pr_date_start = "";
      this.filter.n_pr_date_end = "";
      this.listFilter = [{}];
      this.tanggalPengajuan = "";
      this.forceUpdate();
    },
    goBack() {
      return this.$router.go(-1);
    },
    forceUpdate() {
      this.refreshTable = false;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.el-range-editor {
  width: 100% !important;
}

.el-select {
  width: 100%;
}

.for-cari::placeholder {
  font-weight: 600 !important;
  font-family: Arial, "Font Awesome 5 Free";
}
</style>
