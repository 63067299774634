<template>
  <KTLoader v-if="true" />
  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
    <div class="table-responsive">
      <table
        :class="[loading && 'overlay overlay-block']"
        class="table align-middle table-striped table-row-dashed fs-6 gy-5 dataTable no-footer"
        ref="Datatable"
        id="kt_customers_table"
        role="grid"
      >
        <!--begin::Table head-->
        <thead>
          <!--begin::Table row-->
          <tr
            class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
            role="row"
          >
            <template v-for="(cell, i) in tableHeader" :key="i">
              <th
                @click="
                  sort(
                    cell.sortingField ? cell.sortingField : cell.key,
                    cell.sortable
                  )
                "
                :class="[
                  cell.name && 'min-w-125px',
                  cell.sortable !== false && 'sorting',
                  tableHeader.length - 1 === i &&
                    'text-start b-table-sticky-column',
                  currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}desc` &&
                    'sorting_desc',
                  currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}asc` &&
                    'sorting_asc',
                ]"
                tabindex="0"
                rowspan="1"
                colspan="1"
                style="
                  cursor: pointer;
                  color: black;
                  font-weight: bold;
                  font-size: 14px;
                "
              >
                {{ cell.name }}
              </th>
            </template>
          </tr>
          <!--end::Table row-->
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody class="fw-bold text-gray-600">
          <template v-if="getItems.length">
            <template v-for="(item, k) in getItems" :key="k">
              <tr class="odd">
                <template v-for="(cell, k) in tableHeader" :key="k">
                  <td
                    :class="{
                      'text-start b-table-sticky-column':
                        tableHeader.length - 1 === k,
                    }"
                    style="text-align: left !important"
                  >
                    <slot
                      v-if="cell.key == 'counter'"
                      :name="`cell-${cell.key}`"
                      :row="item"
                    >
                      {{ item.counter }}
                    </slot>
                    <slot v-else :name="`cell-${cell.key}`" :row="item"> </slot>
                  </td>
                </template>
                <!--end::Item=-->
              </tr>
            </template>
          </template>
          <template v-else>
            <tr class="odd">
              <td colspan="7" class="dataTables_empty">
                {{ emptyTableText }}
              </td>
            </tr>
          </template>
        </tbody>
        <div
          v-if="loading"
          class="overlay-layer card-rounded bg-dark bg-opacity-5"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <!--end::Table body-->
      </table>
    </div>

    <div class="row">
      <div
        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
      >
        <div
          v-if="enableItemsPerPageDropdown"
          class="dataTables_length"
          id="kt_customers_table_length"
        >
          Baris
          <label>
            <select
              name="kt_customers_table_length"
              class="form-select form-select-sm form-select-solid"
              @change="setItemsPerPage"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </label>
        </div>
      </div>
      <div
        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
      >
        <el-pagination
          v-model:current-page="pagination.page"
          @current-change="currentPageChange"
          :page-size="pagination.rowsPerPage"
          layout="prev, pager, next"
          :labels="customLabels"
          :prev-text="'Sebelumnya'"
          :next-text="'Selanjutnya'"
          :total="pagination.total"
          :hide-on-single-page="true"
          background
          v-bind:id="'listPagination'"
        >
        </el-pagination>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisibleDelete"
    width="30%"
    custom-class="styleDialogHapus2"
  >
    <div class="text-center">
      <img align="" src="/media/fps/icon_trash.png" style="width: 20%" />
      <h5 class="mt-4 mb-4">Hapus data?</h5>
      <p>Anda yakin ingin menghapus data ini?</p>
    </div>
    <template v-slot:footer>
      <span class="dialog-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-dark mr-2"
          @click="btnHapus(valDelete)"
        >
          Hapus</button
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-light"
          @click="dialogVisibleDelete = false"
        >
          Batal
        </button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ref, getCurrentInstance } from "vue";
import arraySort from "array-sort";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";

import CryptoJS from "crypto-js";
import sha256 from "crypto-js/sha256";

export default {
  components: {
    KTLoader,
    CryptoJS,
    sha256,
  },
  props: {
    tableHeader: { type: Array, required: true },
    filter: { type: Array, default: [] },
    listData: { type: Array, default: [] },
    dataurl: { type: String, default: null },
    search: { type: String, default: null },
    emptyTableText: { type: String, default: "No data found" },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    sendout: { type: Boolean, default: false },
    group: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    totalData: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order_field: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
    groupval: { type: String, default: "" },
    routeurl: { type: String, default: "" },
    locateurl: { type: String, default: "fps" },
    deleteurl: { type: String, default: "" },
    methodeurl: { type: String, default: "post" },
  },
  emits: ["submitInChildParent"],
  data() {
    return {
      currentSort: "",
      getItems: [],
      order: ref(this.order_field),
      label: ref(this.sortLabel),
      timeoutQuery: null,
      pagination: {
        page: 1,
        total: this.totalData,
        rowsPerPage: this.rowsPerPage,
      },
      customLabels: {
        first: "First",
        last: "Last",
        previous: "Previous",
        next: "Next",
      },
      loading: false,
      tablePage: {
        page_size: 10,
        page_index: 1,
        sort: [],
      },
      dialogVisibleDelete: false,
      valDelete: null,
      params: null,
      profile: JSON.parse(this.$store.getters.currentUser),
    };
  },
  watch: {
    search(val) {
      if (val.length > 3) {
        clearTimeout(this.timeoutQuery);
        setTimeout(() => {
          this.fetchData();
        }, 2000);
      } else if (val.length < 1) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.currentSort = this.label + this.order;
    this.pagination.rowsPerPage = this.rowsPerPage;
    this.fetchData();
  },
  methods: {
    paging(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
    fetchData(page = null) {
      this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
      this.loading = true;

      this.filter[0].vendor_id = this.profile.data.vendor_id;
      this.filter[0].vendor_type = this.profile.data.vendor_type_id;

      var params = { ...this.tablePage, ...this.filter[0] };

      if (page != null) {
        params.page_index = page;
      }
      if (this.search != null) {
        params.free_text = this.search;
      }
      this.params = params;

      // start x-signature-key
      var sha256 = CryptoJS.algo.SHA256.create();
      sha256.update(JSON.stringify(this.params));
      var hash = sha256.finalize();
      var hashupper = hash.toString().toUpperCase();

      localStorage.setItem("signature_key", hashupper);
      // end x-signature-key

      if (this.dataurl == null) {
        this.getItems = [];
        if (this.listData.length != 0) {
          let dataList = [];
          if (this.listData.length < 11) {
            dataList = this.paging(
              this.listData,
              this.pagination.rowsPerPage,
              1
            );
          } else {
            dataList = this.paging(
              this.listData,
              this.pagination.rowsPerPage,
              params.page_index
            );
          }
          this.getItems = dataList;
        }
        this.pagination.total = this.listData.length;
        this.loading = false;
        this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      } else {
        this.$apiservice.setHeader();
        if (this.methodeurl == "post") {
          this.$apiservice.post(this.dataurl, params)
            .then((res) => {
              this.getItems = [];
              const data = res.data;
              for (var i = 0; i < data.data.length; i++) {
                data.data[i].counter =
                  this.pagination.page * this.tablePage.page_size -
                  this.tablePage.page_size +
                  i +
                  1;
                this.getItems.push(data.data[i]);
                this.pagination.total = data.total_rows;
              }
            })
            .catch((err) => {
              if (err.response.status == 401) {
                this.$router.push({ name: "401" });
              } else if (err.response.status == 404) {
                this.$router.push({ name: "404" });
              } else if (err.response.status == 500) {
                this.$router.push({ name: "500" });
              }
            })
            .finally(
              () =>
                this.$store.dispatch(
                  Actions.REMOVE_BODY_CLASSNAME,
                  "page-loading"
                ),
              (this.loading = false)
            );
        } else {
          this.$apiservice.get(this.dataurl)
            .then((res) => {
              this.getItems = [];
              const data = res.data;
              for (var i = 0; i < data.length; i++) {
                data.data[i].counter =
                  this.pagination.page * this.tablePage.page_size -
                  this.tablePage.page_size +
                  i +
                  1;
                this.getItems.push(data[i]);
                this.pagination.total = data.length;
              }
            })
            .catch((err) => {
              if (err.response.status == 401) {
                this.$router.push({ name: "401" });
              } else if (err.response.status == 404) {
                this.$router.push({ name: "404" });
              } else if (err.response.status == 500) {
                this.$router.push({ name: "500" });
              }
            })
            .finally(
              () =>
                this.$store.dispatch(
                  Actions.REMOVE_BODY_CLASSNAME,
                  "page-loading"
                ),
              (this.loading = false)
            );
        }
      }
    },
    sort(columnName, sortable) {
      if (sortable === false) {
        return;
      }
      if (this.dataurl == null) {
        if (this.sendout) {
          let order = true;
          if (this.order === "asc") {
            this.order = "desc";
            order = false;
          } else {
            this.order = "asc";
            order = true;
          }
          this.currentSort = columnName + this.order;
          this.tablePage.sort = [];
          this.tablePage.sort.push({
            field: columnName,
            is_ascending: order,
          });
          this.sendToParent();
          return false;
        } else {
          if (this.order === "asc") {
            this.order = "desc";
            arraySort(this.getItems, columnName, { reverse: false });
          } else {
            this.order = "asc";
            arraySort(this.getItems, columnName, { reverse: true });
          }
          this.currentSort = columnName + this.order;
        }
      } else {
        let order = true;
        if (this.order === "asc") {
          this.order = "desc";
          order = false;
        } else {
          this.order = "asc";
          order = true;
        }
        this.currentSort = columnName + this.order;
        this.tablePage.sort = [];
        this.tablePage.sort.push({
          field: columnName,
          is_ascending: order,
        });
      }
      this.fetchData();
    },
    currentPageChange(val) {
      this.fetchData(val);
    },
    setItemsPerPage(event) {
      this.pagination.rowsPerPage = parseInt(event.target.value);
      this.tablePage.page_size = parseInt(event.target.value);
      this.fetchData();
    },
    btnDetail(val) {
      this.$router.push({
        name: this.routeurl + "-detail",
        params: { id: val },
      });
    },
    sendToParent() {
      var params = { ...this.tablePage, ...this.filter[0] };
      this.$emit("submitInChildParent", params);
    },
    btnConfirmHapus(val) {
      this.dialogVisibleDelete = true;
      this.valDelete = val;
      document
        .getElementsByClassName("styleDialogHapus2")[0]
        .setAttribute("id", "dialogHapusData2");
    },
    btnHapus(val) {
      this.dialogVisibleDelete = false;
      this.$apiservice.setHeader();
      this.$apiservice.delete(this.deleteurl + "?id=" + val).then((res) => {
        Swal.fire({
          title: "Data Terhapus",
          html: "Data berhasil dihapus",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        })
          .then(() => {
            this.fetchData();
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.$router.push({ name: "401" });
            } else if (err.response.status == 404) {
              this.$router.push({ name: "404" });
            } else if (err.response.status == 500) {
              this.$router.push({ name: "500" });
            }
          });
      });
    },
  },
};
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
}
.b-table-sticky-column {
  z-index: 9;
  position: -webkit-sticky;
  position: sticky !important;
  right: 0;
  background: #ffff !important;
}
</style>
